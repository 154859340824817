import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericFilter'
})
export class GenericFilterPipe implements PipeTransform {

  transform(items: any[], attribute?): any {
    // I am unsure what id is here. did you mean title?
    if (!attribute) {
      return items.filter(item => item.name.indexOf('manifold') == -1 || item.name.indexOf('t1') == -1);
    } else {
      return items.filter(item => item.name.indexOf('manifold') !== -1 || item.name.indexOf('t1') !== -1);
    }

  }

}
