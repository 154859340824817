import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-binance-classic-acc-row]',
  templateUrl: './binance-classic-acc-row.component.html',
  styleUrls: ['./binance-classic-acc-row.component.scss']
})
export class BinanceClassicAccRowComponent implements OnInit {
  @Input() acc: any;

  constructor() { }

  ngOnInit(): void {
  }

}
