import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: '[app-binance-portfolio-margin-acc-row]',
  templateUrl: './binance-portfolio-margin-acc-row.component.html',
  styleUrls: ['./binance-portfolio-margin-acc-row.component.scss']
})
export class BinancePortfolioMarginAccRowComponent implements OnInit {
  @Input() acc;
  constructor() { }

  ngOnInit(): void {
  }

}
