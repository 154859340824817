import {Component, OnInit} from '@angular/core';
import {AccountService} from '../../services/account.service';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {
  accounts: any[] = [];
  spotBalances = {
    USDT: 0,
    OM: 0,
    BTC: 0,
    ETH: 0
  };
  totalMarginBorrowedOM = 0;
  totalMarginBorrowedUSDT = 0;
  totalMarginCollateralUSDT = 0;
  totalPerpSize = 0;
  totalPerpSizeNotional = 0;
  totalPerpCollateralUSDT = 0;
  totalPerpUnrealizedPNL = 0;
  perpsOnlyFilter: any;
  mainAccOnlyFilter: any;
  nameFilter: any;

  constructor(public accountService: AccountService) {
  }

  ngOnInit(): void {
    this.accountService.fetchAccounts().subscribe((accounts: any) => {
      this.accounts = accounts.map((name: string) => ({name}));
      this.accounts.forEach((account: any) => {
        this.accountService.fetchPositions(account.name).subscribe((data: any) => {
          account.data = data;
          if (data) {
            if (data.papi) {

              let perp = data.positionRisk.filter((q: any) => q.symbol === 'OMUSDT')[0];
              if (perp) {
                this.totalPerpSize += parseFloat(perp.positionAmt);
                this.totalPerpSizeNotional += parseFloat(perp.notional);
                // this.totalPerpCollateralUSDT += perp.initialMargin;
                this.totalPerpUnrealizedPNL += parseFloat(perp.unRealizedProfit || 0);


                account.perpLiquidationLevel = perp.liquidationPrice;
                account.unrealizedPnl = perp.unRealizedProfit;
                account.entryPrice = perp.entryPrice;
                account.perp = perp;

              }

              account.uniMMR = data.account.uniMMR;
              console.log(data);
              if (data.balance) {
                data.balance.forEach(balance => {
                  if (!this.spotBalances[balance.asset]) {
                    this.spotBalances[balance.asset] = 0;
                  }
                  this.spotBalances[balance.asset] += parseFloat(balance.totalWalletBalance);
                })
              }
            } else {
              if (data.balance) {
                Object.keys(data.balance).forEach(key => {
                  if (!this.spotBalances[key]) this.spotBalances[key] = 0;
                  this.spotBalances[key] += parseFloat(data.balance[key].free);
                })
              }
              if (data.margin.marginAccount) {
                let borrowedUSDT = data.margin.marginAccount.userAssets.filter((q: any) => q.asset == 'USDT')[0] || 0;
                this.totalMarginBorrowedUSDT += parseFloat(borrowedUSDT.netAsset || 0);
                let borrowedOM = data.margin.marginAccount.userAssets.filter((q: any) => q.asset == 'OM')[0] || 0;
                data.totalBorrowedOm = borrowedOM.netAsset || 0;


                this.totalMarginBorrowedOM += parseFloat(borrowedOM.netAsset || 0);
                this.totalMarginCollateralUSDT += parseFloat(data.margin.marginAccount.totalCollateralValueInUSDT || 0);
              }
              let perp = data.positions.filter((q: any) => q.symbol === 'OM/USDT:USDT')[0];
              if (perp) {
                this.totalPerpSize += parseFloat(perp.contracts);
                this.totalPerpSizeNotional += perp.notional;
                this.totalPerpCollateralUSDT += perp.initialMargin;
                this.totalPerpUnrealizedPNL += parseFloat(perp.unrealizedPnl || 0);

                account.perpLiquidationLevel = perp.liquidationPrice;
                account.unrealizedPnl = perp.unrealizedPnl;

                account.percentage = perp.percentage;
                account.entryPrice = perp.entryPrice;
              }
            }

          }


        });
      });
    });

  }

  refresh() {
    console.log('Reload UI.');
  }
}
