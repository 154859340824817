<!--<td>-->
<!--  <pre>-->
<!--    {{acc | json}}-->
<!--  </pre>-->
<!--</td>-->
<td>{{acc.name}}</td>
<td>{{acc?.uniMMR}}</td>
<td>{{acc.perpLiquidationLevel}}</td>
<td>{{acc.entryPrice | number: '0.0-2'}}</td>
<td><span *ngIf="acc.unrealizedPnl">{{acc.unrealizedPnl | number: '0.0-0'}} </span></td>
<td><span class="mr-1">{{acc.perp?.positionAmt | number: '0.0-0'}}</span> | <span class="ml-1">{{acc.perp?.notional | number: '0.0-0'}}</span>
  <span style="float: right">{{10000000-acc.perp?.notional | number: '0.0-0'}}</span>
</td>

